<template>
  <div class="list" v-if="!isProcessing">
    <navigation v-if="collections.length > 0" class="list__nav" :active=active />
    <div class="list__item">
      <item v-for="item in displayedItems" :key=item.id :item=item />
    </div>
    <div class="list__warning" v-if="collections.length === 0">
      <p class="list__warning__text">QRコードが未登録です</p>
      <v-btn class="list__warning__btn" depressed @click="$router.push({ name: 'code_add' })">追加する</v-btn>
    </div>
    <v-btn class="list__share" v-if="uname"
           @click="$router.push({ name: 'profile_code', params: { uname: uname } })">
      <v-img class="list__share__icon" :src="require('@/assets/img/icon.svg')" />
    </v-btn>
  </div>
</template>

<script>
import Navigation from '@/components/list/navigation.vue'
import Item from '@/components/list/item.vue'

export default {
  name: 'list',
  components: { Navigation, Item },
  mounted () {
    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 同期処理が必要な情報を取得中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {String} 現在選択中のカテゴリー
     */
    active () {
      return this.$store.getters['active']
    },
    /**
     * @return {Object[]} 表示するURL一覧
     */
    displayedItems () {
      return this.active === 'ALL' ? this.collections : this.collections.filter(item => item.categories.includes(this.active))
    },
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['auth/uid']
    },
    /**
     * @return {Object[]} コレクション一覧
     */
    collections () {
      return this.$store.getters['collections/collections']
    },
    /**
     * @return {String} ユーザー名
     * ユーザー名がnullの場合はプロフィール未編集なので表示しない
     */
    uname () {
      return this.$store.getters['uidUname/uname'](this.uid)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.list {
  width: 100vw;
  max-width: $max_width;
  padding-bottom: 10px;
  margin: $header_height auto calc(#{$footer_height} + 50px + 20px) auto;
  &__nav {
    margin-top: 20px;
  }
  &__item {
    padding: 0 $padding_width;
    margin-top: 20px;
  }
  &__warning {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    &__text {
      font-size: 1.4rem;
      text-align: center;
    }
    &__btn {
      display: block;
      margin: 20px auto 0 auto;
      border-radius: 15px;
      &.v-btn:not(.v-btn--round).v-size--default {
        width: 80px;
        height: 30px;
        font-size: 1.2rem;
        color: $white_color;
      }
      &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        background-color: $orange_color;
      }
    }
  }
  &__share {
    position: fixed;
    right: $padding_width;
    bottom: calc(#{$footer_height} + 20px);
    z-index: $popup_zindex;
    border-radius: 50%;
    &.v-btn:not(.v-btn--round).v-size--default {
      width: 50px;
      min-width: 50px;
      height: 50px;
      padding: 0;
    }
    &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background-color: $orange_lighten_color;
    }
    &__icon {
      max-width: 30px;
      height: 30px;
    }
  }
}
</style>
