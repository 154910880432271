<template>
  <div class="navigation">
    <v-btn v-for="category in allCategories" :key="category" @click="$store.commit('setActive', category)"
           :ref="active === category ? 'active' : ''"
           :class="active === category ? 'navigation__name--active' : 'navigation__name'" outlined>{{ category }}</v-btn>
  </div>
</template>

<script>
import size from '@/assets/sass/size.scss'

export default {
  props: {
    // 選択中のカテゴリー
    active: {
      type: String,
      required: true
    }
  },
  mounted () {
    // カテゴリーをActive要素までスクロール
    const categoriesElem = this.$el
    const activeElem = this.$refs.active[0].$el
    // Activeのx座標を取得
    const activeElemX = window.innerWidth > size.max_width.replace('px', '') ?
      window.pageXOffset + activeElem.getBoundingClientRect().left - (window.innerWidth - size.max_width.replace('px', '') - size.padding_width.replace('px', '')) / 2 :
      window.pageXOffset + activeElem.getBoundingClientRect().left
    // activeが画面内に来るようスクロール、十分な幅がある場合は左端へ表示
    categoriesElem.scrollLeft = activeElemX - size.padding_width.replace('px', '')
  },
  computed: {
    /**
     * @return {String[]} 名前順にソートしたカテゴリー一覧
     * [ 'hoge1', 'hoge2', ... ]
     */
    categories () {
      return this.$store.getters['collections/categories']
    },
    /**
     * @return {String[]} ALLを含めたカテゴリー一覧
     * [ 'ALL', 'お気に入り', 'hoge1', 'hoge2', ... ]
     */
    allCategories () {
      const categories = this.categories.slice()
      // @todo お気に入り機能を導入したらコメントを外す
      // categories.unshift('お気に入り')
      categories.unshift('ALL')
      return categories
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

.navigation {
  box-sizing: border-box;
  width: 100vw;
  max-width: $max_width;
  padding: 0 $padding_width;
  padding-right: $padding_width;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
  &__name {
    display: inline-block;
    margin-right: 10px;
    line-height: 30px;
    text-transform: none;
    vertical-align: middle;
    border: 2px solid $gray_color;
    border-radius: 10px;
    &.v-btn:not(.v-btn--round).v-size--default {
      min-width: 30px;
      height: 30px;
      padding: 0 14px;
      font-size: 1.4rem;
      color: $gray_color;
    }
    &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background-color: transparent;
    }
    &--active {
      @extend .navigation__name;
      border: 2px solid $orange_color;
      &.v-btn:not(.v-btn--round).v-size--default {
        color: $orange_color;
      }
    }
  }
  &__name:last-child {
    margin-right: 0;
  }
}
</style>
