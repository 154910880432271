<template>
  <div class="item">
    <v-img class="item__favicon" :src="'https://www.google.com/s2/favicons?domain=' + item.url" />
    <p class="item__name" @click="$router.push({ name: 'code', params: { cid: item.cid } })"> {{ item.collectionName }}</p>
  </div>
</template>

<script>

export default {
  props: {
    // 表示するQRコード情報
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

.item {
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid $gray_color;
  &__favicon {
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: middle;
  }
  &__name {
    display: inline-block;
    width: calc(100% - 16px - 20px);
    margin: 0;
    margin-left: 20px;
    font-size: 1.4rem;
    word-break: break-all;
    vertical-align: middle;
  }
}
</style>
